<template>
    <div>
        <b-row class="mb-4">
            <b-col cols="12">
                <b-card>
                    <b-row>
                        <b-col>
                            <h4>Self Service Request Search</h4>
                        </b-col>
                        <b-col class="text-right">
                            <b-button v-if="isAdmin==false" variant="primary" squared @click="goToAdd()" size="sm">New Self Service Request</b-button>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="4">
                            <label>Company</label>
                            <b-input-group>
                                <b-form-input v-model="search.company.description" :disabled="true"></b-form-input>
                                <b-input-group-append>
                                    <b-button size="sm" text="Button" variant="secondary" @click="openSearchModal()">Search</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-col>
                        <b-col cols="4">
                            <label>Account Number</label>
                            <b-form-input v-model="search.accountNumber"></b-form-input>
                        </b-col>
                        <b-col cols="4">
                            <label>Experiencing User</label>
                            <b-form-input v-model="search.experiencingUser"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="4">
                            <label>Location</label>
                            <b-form-input v-model="search.site"></b-form-input>
                        </b-col>
                        <b-col cols="4">
                            <label>Date Logged From</label>
                            <b-form-datepicker v-model="search.dateLoggedFrom"></b-form-datepicker>
                        </b-col>
                        <b-col cols="4">
                            <label>Date Logged To</label>
                            <b-form-datepicker v-model="search.dateLoggedTo"></b-form-datepicker>
                        </b-col>
                    </b-row>
                    <hr class="mx-3" />
                    <b-row>
                        <b-col cols="12" class="text-right">
                            <b-button variant="red" squared class="mr-2" @click="clearFilter()">Cancel</b-button>
                            <b-button variant="primary" squared @click="searchServiceRequests()">Search</b-button>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-card>
                    <b-row>
                        <b-col>
                            <b-table striped hover 
                                    :items="tableData.dataSource" 
                                    :fields="tableData.tableColumns"
                                    id="table-display"
                                    @row-clicked="openArea"
                                    :per-page="tableData.resultsPerPage"
                                    :current-page="tableData.currentPage"
                                    :busy="tableData.isLoading" sort-icon-left>
                                <template #table-busy>
                                    <div class="text-center my-2">
                                        <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                                    </div>
                                </template>

                                <!-- <template #cell(solarWindsTicketNumber)="row">
                                    <b-row align-v="center">
                                        <span class="mr-auto">{{padResult(row.item.fkTicketId)}}</span>
                                    </b-row>
                                </template> -->
                                
                                
                                <template #cell(createdDate)="row">
                                    <b-row align-v="center">
                                        <span class="mr-auto">{{row.item.createdDate | dateTimeFilter}}</span>
                                    </b-row>
                                </template>

                                
                                <template #cell(actions)="row">
                                    <b-row align-v="center" align-h="end">
                                        <b-button @click="openArea(row.item)" class="btn-icon" size="sm">
                                            <b-icon-chevron-right></b-icon-chevron-right>
                                        </b-button>
                                    </b-row>
                                </template>

                            </b-table>
                        </b-col>
                    </b-row>
                    <b-row align-h="center" >
                            <b-pagination
                            v-model="tableData.currentPage"
                            :total-rows="rows"
                            :per-page="tableData.resultsPerPage"
                            aria-controls="table-display"
                            ></b-pagination>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>

        
        <b-modal id="search-company-modal" hide-footer size="xl" no-header @close="closeSearchModal()">
            <b-row>
                <b-col cols="4">
                    <label>Name</label>
                    <b-form-input v-model="companySearch.name"></b-form-input>
                </b-col>
            </b-row>
            <hr class="mx-3" />
            <b-row>
                <b-col cols="12" class="text-right">
                    <b-button variant="red" squared class="mr-2" @click="clearCompanyFilter()">Clear</b-button>
                    <b-button variant="primary" squared @click="doSearchCompanies()">Search</b-button>
                </b-col>
            </b-row>
            <b-row>
                <b-table striped hover 
                         :items="tableCompanyData.dataSource" 
                         :fields="tableCompanyData.tableColumns"
                         :busy="tableCompanyData.isLoading"
                        :per-page="tableCompanyData.resultsPerPage"
                        :current-page="tableCompanyData.currentPage"
                         @row-clicked="addCompany" sort-icon-left>

                    <template #table-busy>
                        <div class="text-center my-2">
                        <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                        </div>
                    </template>

                    <template #cell(actions)="row">
                        <b-row align-v="center" align-h="end">
                        <b-button @click="addCompany(row.item)" size="sm" class="btn-icon">
                            <b-icon-chevron-right></b-icon-chevron-right>
                        </b-button>
                        </b-row>
                    </template>
                </b-table>
            </b-row>
            <b-row align-h="center" >
                <b-pagination
                    v-model="tableCompanyData.currentPage"
                    :total-rows="companyRows"
                    :per-page="tableCompanyData.resultsPerPage"
                ></b-pagination>
            </b-row>
            <b-row class="mt-4">
                <b-col class="text-right">
                    <b-button variant="red" class="mr-2" squared @click="closeSearchModal()">Cancel</b-button>
                </b-col>
            </b-row>
        </b-modal>
        
    </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'

export default {
    name: 'Logger',
    data: () => ({
        isAdmin: false,
        search:{
            company: {description: null},
            experiencingUser: null,
            dateLoggedTo: null,
            dateLoggedFrom: null,
            site: null
        },
        companySearch: {
            name: null
        },
        tableCompanyData: {
            totalRecords: 10,
            resultsPerPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: true,
            tableColumns: [ 
                {
                    label: 'Company',
                    key: 'description',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: '',
                    key: 'actions',
                    sortable: false,
                    tdClass: ''
                }
            ]
        },
        tableData: {
            resultsPerPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: true,
            tableColumns: [
                {
                    label: 'Ticket Number',
                    key: 'solarWindsTicketNumber',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Experiencing User',
                    key: 'client',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Location',
                    key: 'location',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Description',
                    key: 'details',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Logged Date',
                    key: 'createdDate',
                    sortable: true,
                    tdClass: ''
                }
            ]
        }
    }),
    created(){
        
        if (this.$route.path.endsWith('admin'))
        {
            console.log(this.$route.path)
            this.isAdmin = true
        }
        this.tableData.isLoading = false
        this.setBreadcrumb([
        {
            text: 'Self Service Request'
        },
        {
            text: 'Search',
            active: true
        }
        ])
        this.searchServiceRequests()
    },
    methods:{
        ...mapActions(['searchSelfServiceRequests', 'searchCompanies']),
        ...mapMutations('breadcrumbService', ['setBreadcrumb']),
        ...mapActions('notificationService', ['addErrorMessage', 'addSuccessMessage']),
        addCompany(rowItem){
            this.search.company = rowItem
            this.closeSearchModal()
        },
        openSearchModal(){
            this.$root.$emit('bv::show::modal', 'search-company-modal', '#btnShow')
            this.doSearchCompanies()
        },
        closeSearchModal(){
            this.$root.$emit('bv::hide::modal', 'search-company-modal', '#btnShow')
        },
        doSearchCompanies(){
            this.tableCompanyData.isLoading = true
            let request = []
            if(this.companySearch.name !== null){
                request.push({"key":"companyName","value": this.companySearch.name})
            }
            this.$store.commit('setSearchCompaniesRequest', request)
            this.searchCompanies()
            .then((res) => {
                this.tableCompanyData.dataSource = res.data
                this.tableCompanyData.isLoading = false
            })
            .catch(() => {
                this.tableCompanyData.isLoading = false
            })
        },
        openArea(item){
            this.$store.commit('setSelectedJobCard', item)
            this.$router.push({path: '/selfService/view'})
        },
        clearCompanyFilter(){
            this.companySearch = {
                name: null
            }
            this.doSearchCompanies()
        },
        clearFilter(){
            this.search = {
                company: {description: null},
                experiencingUser: null,
                dateLoggedTo: null,
                dateLoggedFrom: null,
                site: null
            }
            this.searchServiceRequests()
        },
        padResult(item){
            if (item !== null && item !== undefined) {
                let newItem = item.toString().padStart(6, '0')
                return newItem
            }
            else{
                return 'N/A'
            }
        },
        searchServiceRequests(){
            this.tableData.isLoading = true
            let request = []

            if (this.isAdmin == true)
            {
                request.push({"key":"isAdmin","value": true})
            }
            if(this.search.company.description !== null){
                request.push({"key":"companyId","value": this.search.company.id})
            }
            if(this.search.experiencingUser !== null){
                request.push({"key":"Name","value": this.search.experiencingUser})
            }
            if(this.search.dateLoggedTo !== null){
                request.push({"key":"createdDateTo","value": this.search.dateLoggedTo})
            }
            if(this.search.dateLoggedFrom !== null){
                request.push({"key":"createdDateFrom","value": this.search.dateLoggedFrom})
            }
            if(this.search.site !== null){
                request.push({"key":"locationName","value": this.search.site})
            }
            this.$store.commit('setSsrSearchRequest', request)
            this.searchSelfServiceRequests()
            .then((res) => {
                //console.log(res)
                this.tableData.dataSource = res.data
                this.tableData.isLoading = false
            })
            .catch(() => {
                this.tableData.isLoading = false
            })
        },
        goToAdd(){
            this.$router.push({path: '/selfService/add'})
        }
    },
  computed: {
      rows() {
          return this.tableData.dataSource.length
      },
      companyRows(){
        return this.tableCompanyData.dataSource.length
      }
  },
}
</script>
